.icon {
  &-wrapper {
    padding: 4px;

    &.pointer {
      cursor: pointer;
      border-radius: 50%;
      transition: 250ms;

      &:hover {
        background-color: #ebf0f4;
      }
      &:active {
        background-color: #dee5ed;
      }
    }
  }
  &-md {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &-group {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgb(190, 190, 190);
  }
  &-btn {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgb(190, 190, 190);
  }
  &-switch-signle {
    padding: 5px;
    background-color: #ebeff3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:hover {
      background-color: #d3d2d2;
    }
  }
  &-switch {
    padding: 5px;
    background-color: #ffffff;
    cursor: pointer;
    &:first-child {
      border-right: 1px solid rgb(190, 190, 190);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-child(2) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:hover {
      background-color: #d3d2d2;
    }
  }
  &-tabler {
    display: flex;
    width: 24px;
    height: 24px;
    color: rgb(153, 152, 152);
    h6 {
      align-items: center;
    }
    &-toggle {
      width: 33px;
      height: 16px;
    }
    &-lock-display {
      width: 16px;
      height: 16px;
    }
    &-lock-display-2 {
      width: 24px;
      height: 24px;
    }
    &-dotcss {
      width: 10px;
      height: 10px;
    }
    $icons: 'facebook', 'gitlab', 'github';
    @for $i from 1 through length($icons) {
      $icon: unquote(nth($icons, $i));
      $image: unquote('/#{$icon}.svg');
      &-#{$icon} {
        background-image: url(./icons/#{$image});
      }
    }
  }
}
.socialNetwork  h6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
