body {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;
}
.container {
  overflow-y: auto;
  max-height: 100vh;
}
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.infoBasic {
  .card-group {
    .index {
      position: absolute;
      color: black;
      left: 20px;
      top: 5px;
      font-size: 30px;
      z-index: 1;
      font-weight: bold;
      font-style: italic;
      font-family: 'circular';
    }
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
  h6 {
    color: #050505;
    font-weight: 700;
  }
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*='col-'] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.about-me-content {
  li {
    font-size: 10px;
    letter-spacing: 2px;
    padding: 2px;
  }
}
